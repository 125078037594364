<script setup lang="ts">
/* Pinia stores */

/**
 * The common Pinia store.
 */
const commonStore = useCommonStore();

/* Computed variables */

/**
 * The submenu links.
 */
const submenuLinks: ComputedRef<SubmenuLink[]> = computed(
  () => commonStore.submenu ?? [],
);
</script>

<template>
  <nav id="submenu">
    <SubmenuLink
      v-for="link in submenuLinks"
      :to="link.route"
      :text="link.text"
    />
  </nav>
</template>

<style scoped>
#submenu {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 10rem;
  padding-top: 9.625rem;
  gap: 0.625rem;
}
</style>
